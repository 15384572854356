@import '../../assets/style/variables.scss';

.header {
  display: flex;
  justify-content: center;
  height: 17vh;
  .header__logo {
    display: flex;
    align-items: center;
    -webkit-animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1)
      both;
    animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1) both;
    img {
      width: 10rem;
      height: 10rem;
    }
    h1 {
      font-family: 'Luckiest Guy', cursive;
      font-size: 4rem;
      font-weight: bold;
      color: $color-blue-dark;
      letter-spacing: 4px;
      text-shadow: 4px 3px 5px rgba(0, 0, 0, 0.83);
      .center_logo {
        color: $color-pink;
      }
    }
  }
  .header__social {
    display: flex;
    align-items: center;

    a {
      width: 3rem;
      height: 3rem;
      background-color: $color-blue-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.3rem;
      border-radius: 50%;
      img {
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;
        cursor: pointer;
      }
    }
  }
}

@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .header {
    .header__logo {
      img {
        width: 7rem;
        height: 7rem;
      }
      h1 {
        font-size: 2.3rem;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .header {
    .header__logo {
      a {
        img {
          width: 6rem;
          height: 6rem;
        }
      }
      h1 {
        font-size: 2.1rem;
      }
    }
  }
}
