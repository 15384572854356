@import '../../assets/style/variables.scss';

.footer {
  height: auto;
  margin-bottom: 0;
  .icon {
    display: flex;
    justify-content: center;
  }
  .copyrigth {
    text-align: center;
    margin-bottom: 0;
    font-size: 1rem;
    color: white;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .container {
    margin: 0 2rem;
    width: 62%;
    margin: 0 auto;
    .alphabet_search {
      margin: 0;
      text-align: left;
      color: white;
    }
    .row {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;
      margin: 0 auto;
      .letter {
        color: white;
        background-color: $color-blue-dark;
        border-radius: 90%;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        margin: 0.5rem 0;
        -webkit-box-shadow: 5px 5px 15px 5px #000000;
        box-shadow: 5px 5px 15px 5px #000000;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: $color-blue-light;
        }
      }
    }
  }
}

@media screen and (max-width: 1547px) {
  .footer {
    .container {
      width: 73%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    .container {
      width: 90%;
    }
  }
}

@media screen and (max-width: 945px) {
  .footer {
    .container {
      width: 90%;
      .row {
        .letter {
          width: 1.5rem;
          height: 1.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .footer {
    .container {
      width: 100%;
      .row {
        .letter {
          width: 1.5rem;
          height: 1.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    .copyrigth {
      font-size: 0.8rem;
    }
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .container {
      width: 95%;
      display: none;
      .row {
        .letter {
          width: 1rem;
          height: 1rem;
          font-size: 0.7rem;
        }
      }
    }
  }
}
