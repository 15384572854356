@media print {
  .print_recipe_all {
    margin-bottom: 0 !important;
  }
  .print {
    display: block;
    page-break-before: auto;
  }
  .imageRecipe {
    width: 640px !important;
    height: 480px !important;
    margin: 0 auto !important;
  }
  .footer {
    display: none !important;
  }
  .number_comments_header {
    display: none !important;
  }
  .print_cursor {
    display: none !important;
  }
  // .recipe-people_selector {
  //   position: absolute;
  //   top: 0;
  //   right: -30rem;
  // }
}
