.cards {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
  .MuiPaper-root {
    margin: 0 auto;
    &:hover {
      cursor: pointer;
    }
  }
}
