@import '../../assets/style/variables.scss';

.MuiInputBase-root {
  border-radius: 0 !important;
}

.MuiGrid2-root {
  .MuiFormControl-root {
    .Mui-focused {
      color: $color-blue-dark !important;
      outline: none !important;
    }
  }
}

.searchField {
  #input_search {
    height: 3rem;
    border: 1px solid $color-blue-dark;
    border-radius: 0;
    padding: 0 1rem;
    background-color: white;
    color: $color-blue-dark;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    color: $color-blue-dark;

    &:focus {
      outline: none;
    }
  }
  button {
    border: 1px solid $color-blue-dark;
    background-color: $color-blue-dark;
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    &:hover {
      background-color: $color-blue-light;
    }
  }
  .fridge {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .clear {
    border-radius: 0 1.5rem 1.5rem 0;
  }
}
