/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  margin: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  height: 100vh;
  /* background-attachment: fixed; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#root {
  background: url(./assets/images/bg.jpg) center center / cover;
}
