@import '../../assets/style/variables.scss';

.swiper-button-next {
  color: $color-blue-dark;
}

.swiper-button-prev {
  color: $color-blue-dark;
}

.swiper-wrapper {
  align-items: center;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.MuiCircularProgress-root {
  height: 10rem !important;
  width: 10rem !important;
}

@media screen and (max-width: 800px) {
  .MuiCircularProgress-root {
    left: 35% !important;
  }
}

@media screen and (max-width: 500px) {
  .MuiCircularProgress-root {
    left: 30% !important;
  }
}
